import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

export default function NewPatientPage({ data }) {
  return (
    <>
      <SEO title="Our Process" />
      <div>
        <section className="container my-5" id="patient">
          <div className="px-3 g-4">
            <h1 className="mb-3">New Patient Intake Forms</h1>
            <h2 className="card-subtitle mb-2 text-muted h4">
              Save Time On Your First Visit.
            </h2>
          </div>
          <div className="new-patient__container">
            <div>
              <embed
                src="/NEW_PATIENT_INTAKE.pdf"
                type="application/pdf"
                // frameBorder="0"
                scrolling="auto"
                width="600px"
                height="790px"
                style={{
                  margin: "18px",
                  borderRadius: "5px",
                  boxShadow:
                    "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                }}
              />
            </div>
            <div>
              <div className="card" style={{ border: "none" }}>
                <div className="card-body">
                  <h3 className="card-title">Our Process</h3>
                  <ol>
                    <li>
                      <p className="card-text">
                        On your first visit you can expect to discuss your
                        health concerns with the doctor during the consultation,
                        which is followed by a comprehensive exam performed by
                        the doctor to assess your alignment, joint function,
                        range of motion, and how your body is manifesting
                        stress.
                      </p>
                    </li>
                    <li className="mt-3">
                      <p className="card-text">
                        Digital x-rays (if warranted) are taken to further
                        assess your alignment and spinal/joint health.
                      </p>
                    </li>
                    <li className="mt-3">
                      <p className="card-text">
                        The doctor will review your exam findings, x-ray results
                        and the best course of treatment specific to you.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <a
                href="/NEW_PATIENT_INTAKE.pdf"
                download="new_patient_intake"
                className="btn btn-primary"
                target="_blank"
                style={{ maxWidth: "270px", margin: "12px" }}
              >
                Download And Print Intake Form
              </a>
            </div>
          </div>
          <a
            href="https://urbancarechiropractic.schedulista.com/"
            className="btn btn-primary"
            target="_blank"
            style={{ maxWidth: "270px", margin: "12px" }}
          >
            Schedule Appointment
          </a>
        </section>
      </div>
    </>
  )
}
export const query = graphql`
  query {
    posts: allSanityPost {
      nodes {
        date
        description
        id
        link
        title
      }
    }
    reviews: allSanityReview {
      nodes {
        id
        demo
        name
        rating
        review
        photo {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    services: allSanityServices {
      nodes {
        id
        name
        description
        demo
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    experts: allSanityExperts {
      nodes {
        id
        description
        motto
        name
        specialty
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    images: allSanityImages(filter: { active: { eq: true } }) {
      nodes {
        id
        title
        subtitle
        active
        image {
          asset {
            metadata {
              dimensions {
                height
                width
              }
            }
            fluid(maxWidth: 2560) {
              ...GatsbySanityImageFluid
            }
          }
          hotspot {
            y
            x
            width
            height
          }
          crop {
            bottom
            left
            right
            top
          }
        }
      }
    }
  }
`
